import { Link } from 'react-router-dom'
import myImage from '../images/clouds.png'
import { useWindowScroll } from '@mantine/hooks'
import { useEffect } from 'react'
// eslint-disable-next-line no-unused-vars
import { Timeline, Text } from '@mantine/core';
import PlainNavbar from '../components/PlainNavbar'
import DexScreenerChart from '../components/DexScreenerChart';
// import PresaleSection from '../components/PresaleSection';

const MiniHome = () => {
    const [, scrollTo] = useWindowScroll()

    useEffect(() => {
        scrollTo({ y: 0 })
    }, [scrollTo])

    return <>

        <PlainNavbar />
        <div className='homepage'>
            <div className='section' style={{
                backgroundImage: `url(${myImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
            }}>
                <div className='intro'>
                    <p className='logo'>JET<b>VOY</b></p>
                    <p className='head_txt'>Revolutionizing the travel industry with <b>blockchain technology</b></p>
                </div>
                <p className='intro_txt'>Jetvoy revolutionalizes travel bookings with its cutting edge platform offering flights, hotels, resorts,
                    cruises, and car rentals, all enhanced with our unique loyalty point system
                    based on the JetVoy token.
                </p>
                <div className='btn_links'>
                    <button
                        type="button"
                        onClick={(e) => {
                        e.preventDefault();
                        window.location.href='/JetvoyWhitepaperV2.pdf';
                        }}
                    >whitepaper</button>
                    <button
                        type="button" target="_new"
                        onClick={(e) => {
                        e.preventDefault();
                        window.open('https://www.jetvoy.com','_blank')
                        }}
                    >Booking Site</button>
                    <button
                        type="button" target="_new"
                        onClick={(e) => {
                        e.preventDefault();
                        window.open('https://app.uniswap.org/swap?chain=base&exactField=input&outputCurrency=0xE58b3AB1fB80304dE37a507A2449Fea64bF0B507&inputCurrency=ETH','_blank')
                        }}
                    >Buy $VOY</button>
                    {/*<button className="pinksale" href="#"
                    type="button" target="_new"
                    onClick={(e) => {
                    e.preventDefault();
                    window.open('https://www.pinksale.finance/launchpad/0x72f2A12FF720632752D8c458932A04FD90712Ac4?chain=BSC','_blank')
                    }}
                >Pinksale</button>*/}
                    <div className='icon_links'>
                        <Link to="https://www.facebook.com/jetvoyofficial" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></Link>
                        <Link to="https://x.com/jetvoytoken" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></Link>
                        <Link to="https://t.me/Jetvoyofficial" target="_blank" rel="noopener noreferrer"><i className="fab fa-telegram-plane"></i></Link>
                        {/*<Link to="https://www.reddit.com/user/Jetvoy/" target="_blank" rel="noopener noreferrer"><i className="fab fa-reddit-alien"></i></Link>*/}
                        <Link to="https://www.instagram.com/jetvoy.official/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></Link>
                    </div>                    
                </div>                
                {/*<PresaleSection/>*/}

                {/*<div className='re_dir'>
                    <span>
                        <i className="#"></i>
                        Binance
                    </span>
                    <span>
                        <i className="#"></i>
                        Crypto.com
                    </span>
                    <span>
                        <i className="#"></i>
                        Bscscan
                    </span>
                    <span>
                        <i className="#"></i>
                        Dappradar
                    </span>
                    <span>
                        <i className="#"></i>
                        Medium
                    </span>
                </div>*/}
                
            </div>
            <div className='section'>
                    <div className='segment intro'>
                        Contract Address: 0xE58b3AB1fB80304dE37a507A2449Fea64bF0B507
                    </div>
            </div>
            <div className='section'>
                <div className='segment'>
                    <div className='chart'>
                        <DexScreenerChart />
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default MiniHome
